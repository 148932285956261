$(function() {
  window.monumento || (window.monumento = {});
  window.monumento.dataURItoBlob = function(data) {
    var ab, bb, byteString, i, ia, mimeString;
    mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    byteString = atob(data.split(',')[1]);
    ab = new ArrayBuffer(byteString.length);
    ia = new Uint8Array(ab);
    i = 0;
    while (i < byteString.length) {
      ia[i] = byteString.charCodeAt(i);
      i++;
    }
    bb = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
    if (bb) {
      bb = new (window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder);
      bb.append(ab);
      return bb.getBlob(mimeString);
    } else {
      bb = new Blob([ab], {
        'type': mimeString
      });
      return bb;
    }
  };
  window.monumento.canvasResize = function(file, options) {
    var JPEG_QUALITY, RESIZE_RATIO, createObjectURL, filereader;
    if (!file) {
      return;
    }
    RESIZE_RATIO = 0.2;
    JPEG_QUALITY = 0.8;
    createObjectURL = window.URL && window.URL.createObjectURL ? (function(file) {
      return window.URL.createObjectURL(file);
    }) : window.webkitURL && window.webkitURL.createObjectURL ? (function(file) {
      return window.webkitURL.createObjectURL(file);
    }) : void 0;
    filereader = new FileReader();
    filereader.onload = function(e) {
      var image, url;
      url = createObjectURL ? createObjectURL(file) : e.target.result;
      return image = $('<img>').on('load error', function() {
        var data, jpegEnc, origSize, targetSize, tmpCvs, tmpCxt;
        tmpCvs = document.createElement('canvas');
        tmpCxt = tmpCvs.getContext('2d');
        origSize = {
          width: this.width,
          height: this.height
        };
        targetSize = {
          width: origSize.width * RESIZE_RATIO,
          height: origSize.height * RESIZE_RATIO
        };
        tmpCvs.width = targetSize.width;
        tmpCvs.height = targetSize.height;
        tmpCxt.drawImage(this, 0, 0, origSize.width, origSize.height, 0, 0, targetSize.width, targetSize.height);
        jpegEnc = new JPEGEncoder();
        data = jpegEnc.encode(tmpCxt.getImageData(0, 0, targetSize.width, targetSize.height), 0.8 * 100, true);
        return options.callback(data, targetSize.width, targetSize.height);
      }).attr('src', url);
    };
    return filereader.readAsDataURL(file);
  };
  window.monumento.is_android = function() {
    if (window.navigator.userAgent.indexOf(' Android') !== -1) {
      return true;
    } else {
      return false;
    }
  };
  window.monumento.is_mobile = function() {
    if (window.navigator.userAgent.indexOf('iPhone') !== -1 || window.navigator.userAgent.indexOf('iPod') !== -1 || window.navigator.userAgent.indexOf('iPad') !== -1 || window.navigator.userAgent.indexOf('Android') !== -1 || window.navigator.userAgent.indexOf('Monumento-iOS') !== -1 || window.navigator.userAgent.indexOf('Monumento-Android') !== -1) {
      return true;
    } else {
      return false;
    }
  };
  window.monumento.addressFieldPressed = function(event, map) {
    var address;
    if (!event) {
      event = window.event;
    }
    address = $('.address-panel #address').val();
    if (event.keyCode === 13 && address) {
      return window.monumento.panToAddress(map, address);
    }
  };
  return window.monumento.panToAddress = function(map, address) {
    var geocoder;
    geocoder = new google.maps.Geocoder();
    return geocoder.geocode({
      'address': address
    }, (function(_this) {
      return function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
          return $('.address-panel #address').blur();
        } else {
          return alert("「" + address + "」には移動できません。");
        }
      };
    })(this));
  };
});

// ---
// generated by coffee-script 1.9.2
